import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import countdown from 'countdown';

export default class CountDown extends PureComponent {
    static propTypes = {
        dateToCount: PropTypes.object.isRequired,
        isShort: PropTypes.bool,
        isTiny: PropTypes.bool,
        isPast: PropTypes.bool
    };

    state = {
        now: new Date()
    };

    componentDidMount() {
        if (this.props.isShort || this.props.isTiny) {
            countdown.setLabels('|s|m|hr|d|w|month', '|s|m|hrs|d|w|months', ' and ');
        }

        this.interval = window.setInterval(() => {
            this.setState({
                now: new Date()
            }, () => {
                if (!this.props.isPast) {
                    if (this.state.now.getTime() >= this.props.dateToCount.getTime()) {
                        window.clearInterval(this.interval);
                    }
                }
            });
        }, 1000);
    }

    componentWillUnmount() {
        window.clearInterval(this.interval);
    }

    render() {
        if (this.props.isShort) {
            return (
                <Fragment>
                    {this.props.isPast || (this.state.now.getTime() <= this.props.dateToCount.getTime()) ? countdown(this.state.now, this.props.dateToCount, countdown.MONTHS | countdown.WEEKS | countdown.DAYS | countdown.HOURS | countdown.MINUTES, 2).toString() : 'expired'}
                </Fragment>
            );
        } else if (this.props.isTiny) {
            return (
                <Fragment>
                    {this.props.isPast || (this.state.now.getTime() <= this.props.dateToCount.getTime()) ? countdown(this.state.now, this.props.dateToCount, countdown.MONTHS | countdown.WEEKS | countdown.DAYS | countdown.HOURS | countdown.MINUTES, 1).toString() : 'expired'}
                </Fragment>
            );
        }

        return (
            <Fragment>
                {this.props.isPast || (this.state.now.getTime() <= this.props.dateToCount.getTime()) ? countdown(this.state.now, this.props.dateToCount, countdown.MONTHS | countdown.WEEKS | countdown.DAYS | countdown.HOURS | countdown.MINUTES | countdown.SECONDS, 3).toString() : 'expired'}
            </Fragment>
        );
    }
}
