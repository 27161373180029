import { format } from 'date-fns';

/****
 *
 * window & process.env constants
 *
 */
export const ELOQUENT = window.Eloquent;
export const AXIOS = window._BaseAxios;

export const MAP_KEY = '49b3e500-ec83-11ec-9940-db2b61467020';

export const APP_ENV = process.env.REACT_APP_ENV;
export const APP_URL = process.env.REACT_APP_URL;
export const API_URL = process.env.REACT_APP_API_URL;

/****
 *
 * Storage keys
 *
 */
export const STORAGEKEY_AUTH_TOKEN = 'ts-authToken';
export const STORAGEKEY_APP_LANGUAGE = 'ts-appLanguage';

export const S3_STORAGE_URI = 'https://tender-production.s3-ap-southeast-1.amazonaws.com/uploads/resources/';

/****
 *
 * Configs
 *
 */
// App default language (also used for the <html /> lang='' attribute)
// see https://developer.paciellogroup.com/blog/2016/06/using-the-html-lang-attribute/
// NOTE: make sure the language key defined here matches the default helmet key
// // see `executors/i18n/helmet`
export const APP_LANGUAGE_DEFAULT = 'fr';

// App environments
// see .env.production & .env.development
// values must match the values defined in these files
export const APP_ENVS = {
    LOCAL: 'local',
    STAGING: 'staging',
    PRODUCTION: 'production'
};

// SENTRY - see https://docs.sentry.io/error-reporting/configuration/?platform=javascript
export const SENTRY_CONFIG = {
    environment: APP_ENV,
    dsn: ''
};

// WEBFONT config - see https://github.com/typekit/webfontloader
export const WEBFONT_CONFIG = {
    google: {
        families: [
            'Lato:300',
            'Lato:400',
            'Lato:700',
            'Lato:900'
        ]
    }
};

// Social networks config
export const SOCIAL_NETWORK_URLS = {
    FACEBOOK: '',
    INSTAGRAM: '',
    TWITTER: '',
    LINKEDIN: ''
};

export const FACEBOOK_APP_ID = 'TODO_REPLACE';

/****
 *
 * App routing
 *
 */
export const ROUTES = {
    DEFAULT: '/',

    LANDING: '/'
};

export const ROUTE_REDIRECT_AFTER_REGISTRATION = ROUTES.LANDING;
export const ROUTE_REDIRECT_AFTER_LOGIN = ROUTES.LANDING;
export const ROUTE_REDIRECT_FROM_UNAUTHED_ROUTE = ROUTES.LANDING;
export const ROUTE_REDIRECT_FROM_AUTHED_ROUTE = ROUTES.LANDING;

/****
 *
 * Project config
 *
 */
export const TENDER_COPYRIGHT = `© Tender Sale ${format(new Date(), 'yyyy')}`;

export const USER_ROLE_TYPES = {
    BUYER: 1,
    SELLER: 2
};

export const ROUTES_WITH_HEADER_NAV = [
    ROUTES.LANDING
];

export const ROUTES_WITH_SIDE_NAV = [

];

export const PRODUCT_CATEGORIES = [
    {
        label: 'Smartphones',
        value: 1
    },
    {
        label: 'Tablets',
        value: 2
    },
    {
        label: 'Bluetooth Speakers',
        value: 3
    },
    {
        label: 'Cameras',
        value: 4
    },
    {
        label: 'Other Electronics',
        value: 5
    }
];

export const dummyPhones = [
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-phone.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-phone.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-phone.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-phone.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-phone.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-phone.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-phone.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-phone.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-phone.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-phone.jpg'
    }
];

export const dummyTablet = [
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-tablet.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-tablet.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-tablet.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-tablet.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-tablet.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-tablet.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-tablet.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-tablet.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-tablet.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-tablet.jpg'
    }
];

export const dummyLaptop = [
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-laptop.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-laptop.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-laptop.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-laptop.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-laptop.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-laptop.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-laptop.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-laptop.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-laptop.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-laptop.jpg'
    }
];

export const dummyPC = [
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-pc.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-pc.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-pc.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-pc.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-pc.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-pc.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-pc.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-pc.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-pc.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-pc.jpg'
    }
];

export const dummySpeaker = [
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-speaker.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-speaker.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-speaker.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-speaker.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-speaker.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-speaker.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-speaker.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-speaker.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-speaker.jpg'
    },
    {
        id: 1,
        title: 'Product Name',
        colors: 3,
        image: '/images/product-speaker.jpg'
    }
];

export const dummyProducts = [
    [],
    dummyPhones,
    dummyTablet,
    dummyLaptop,
    dummyPC,
    dummySpeaker
];
