import { ELOQUENT } from 'config/constants';

/**
 * Delete all notifications
 * @returns {Promise}
 */
export function deleteNotifications() {
    return ELOQUENT.Notifications.deleteAll();
}

/**
 * Read all notifications
 * @returns {Promise}
 */
export function readNotifications() {
    return ELOQUENT.Notifications.readAll();
}

/**
 * Read a notifications
 * @param {Number} id
 * @returns {Promise}
 */
export function readOneNotifications(id) {
    return ELOQUENT.Notifications.readNotification(id);
}

/**
 * Get Notification count
 * @returns {Promise}
 */
export function getNotificationCount() {
    return ELOQUENT.Notifications.getNotificationCount();
}

/**
 * Get short notification list
 * @returns {Promise}
 */
export function getShortNotifications() {
    return ELOQUENT.Notifications.getShortNotifications();
}

/**
 * Paginated list of notifications
 * @param {Object} data
 * @returns {Promise}
 */
export function getAllNotifications(data) {
    return ELOQUENT.Notifications.getAllNotifications(data);
}

/**
 * Send new auction notifications
 * @param {Number} id
 * @returns {Promise}
 */
export function newAuctionAddedNotification(id) {
    return ELOQUENT.Notifications.newAuctionAdded({
        auction_id: id
    });
}

/**
 * Send new bid notifications
 * @param {Number} id
 * @returns {Promise}
 */
export function newBidAddedNotification(id) {
    return ELOQUENT.Notifications.newBidAdded({
        bid_id: id
    });
}
