import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import './header-nav.scss';
import SearchInput from '../SearchInput/SearchInput';
import { closeModal, enqueueModal } from '../../redux/modules/modal';
import SignupModal from '../SignupModal/SignupModal';
import { logout, unsetApiRequestToken } from '../../api/auth';
import { loggingOut } from '../../redux/modules/authentication';
import Suggestion from '../Modals/Suggestion';

export default class UserButton extends PureComponent {
    static propTypes = {
        me: PropTypes.object,
        dispatch: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired
    };

    state = {
        isMenuOpened: false
    };

    componentDidMount() {
        document.addEventListener('click', this.documentClicked, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.documentClicked);
    }

    toggleMenu = (e) => {
        e && e.preventDefault();

        this.setState({
            isMenuOpened: !this.state.isMenuOpened
        });
    };

    onLogout = async (e) => {
        e.preventDefault();

        try {
            await logout();
        } finally {
            unsetApiRequestToken();

            this.props.dispatch(loggingOut());

            this.props.history.push('/');
        }
    };

    suggest = (e) => {
        e.preventDefault();
        this.props.dispatch(enqueueModal({
            data: <Suggestion onClose={this.onSuggestClose}/>,
            hideTitle: false
        }));
    };

    onSuggestClose = () => {
        this.props.dispatch(closeModal());
    }

    documentClicked = (e) => {
        if (this.state.isMenuOpened) {
            const menuComp = e.target.closest('.header-user');

            if (!menuComp) {
                this.toggleMenu();
            }
        }
    }

    render() {
        if (!this.props.me) {
            return null;
        }

        return (
            <div className={'header-user'} onClick={this.toggleMenu}>
                <div className={'header-user__avatar'}>
                    <img src={this.props.me.avatar || '/images/avatar.jpg'} alt={''}/>
                </div>
                {this.props.me.role_type === 1 ? (
                    <div className={`header-user__list ${this.state.isMenuOpened ? 'opened' : ''}`}>
                        {this.props.me.is_admin ? <Link to={'/product-form/new'} className={'header-user__item'}>New Product</Link> : null }
                        <Link to={'/my-auctions'} className={'header-user__item'}>My Auctions</Link>
                        <Link to={'/my-orders'} className={'header-user__item'}>My Orders</Link>
                        <Link to={'/account'} className={'header-user__item'}>My Account</Link>
                        <Link to={'/'} onClick={this.suggest} className={'header-user__item top-border'}>Suggest a Product</Link>
                        <Link to={'/'} onClick={this.onLogout} className={'header-user__item top-border red-labeled'}>Logout</Link>
                    </div>
                ) : (
                    <div className={`header-user__list ${this.state.isMenuOpened ? 'opened' : ''}`}>
                        {this.props.me.is_admin ? <Link to={'/product-form/new'} className={'header-user__item'}>New Product</Link> : null }
                        <Link to={'/dashboard'} className={'header-user__item'}>Dashboard</Link>
                        <Link to={'/my-orders'} className={'header-user__item'}>Orders</Link>
                        <Link to={'/my-inventory'} className={'header-user__item'}>My Inventory</Link>
                        <Link to={'/account'} className={'header-user__item'}>My Account</Link>
                        <Link to={'/'} onClick={this.suggest} className={'header-user__item top-border'}>Suggest a Product</Link>
                        <Link to={'/'} onClick={this.onLogout} className={'header-user__item top-border red-labeled'}>Logout</Link>
                    </div>
                )}
            </div>
        );
    }
}
