import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import './search-input.scss';
import connect from 'react-redux/es/connect/connect';
import Select from 'react-select';
import url from 'url-parameters';
import { PRODUCT_CATEGORIES } from 'config/constants';
import { getCategories } from '../../api/products';

class SearchInput extends PureComponent {
    static propTypes = {
        dispatch: PropTypes.func,
        onSearch: PropTypes.func.isRequired,
        location: PropTypes.object.isRequired
    };

    state = {
        isLoading: true,
        query: '',
        category: {},
        categories: []
    };

    componentDidMount() {
        this.loadCategories();
    }

    componentDidUpdate(oldProps) {
        if (oldProps.location.search !== this.props.location.search) {
            const query = url.get('query') || '';
            const categoryId = parseInt(url.get('category') || this.state.categories[0].value);
            const category = this.state.categories.find((cat) => cat.value == categoryId) || this.state.categories[0];

            this.setState({
                query,
                category
            });
        }
    }

    async loadCategories() {
        try {
            const query = url.get('query') || '';

            const resp = await getCategories();

            const categories = resp.data.map((c) => {
                return {
                    value: c.id,
                    label: c.title
                };
            });
            const categoryId = parseInt(url.get('category') || categories[0].value);
            const category = categories.find((cat) => cat.value == categoryId) || categories[0];

            this.setState({
                query,
                category,
                categories: categories,
                isLoading: false
            });
        } catch (err) {
            console.error(err);
        }
    }

    onSearch = (e) => {
        e && e.preventDefault();
        this.props.onSearch(`/search?query=${this.state.query}&category=${this.state.category.value}`);
    };

    onQueryChange = (e) => {
        this.setState({
            query: e.target.value
        });
    };

    onCategoryChange = (value) => {
        this.setState({
            category: value
        });
    };

    render() {
        return (
            <form className={'search-input'} onSubmit={this.onSearch}>
                <img src={'/images/search.png'} alt={''} className={'search-input__img'}/>
                <input
                    type={'text'}
                    name={'query'}
                    onInput={this.onQueryChange}
                    onChange={() => {}}
                    value={this.state.query}
                    placeholder={'What are you looking for?'}/>
                {!this.state.isLoading ? <Select
                    isMulti={false}
                    isSearchable={false}
                    onChange={this.onCategoryChange}
                    defaultValue={this.state.category}
                    classNamePrefix={'ssl'}
                    className={'ssl__container'}
                    options={this.state.categories}/> : null}
                <button type={'submit'}>Search</button>
            </form>
        );
    }
}

function mapStateToProps() {
    return {

    };
}

export default connect(
    mapStateToProps
)(SearchInput);
