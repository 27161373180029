import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import connect from 'react-redux/es/connect/connect';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import { closeModal } from 'redux/modules/modal';

class ModalContainer extends PureComponent {
    static propTypes = {
        dialogStyle: PropTypes.object,
        dispatch: PropTypes.func.isRequired,
        data: PropTypes.element,
        onOpen: PropTypes.func,
        onClose: PropTypes.func,
        title: PropTypes.any,
        isVisible: PropTypes.bool.isRequired,
        hideTitle: PropTypes.bool,
        classes: PropTypes.string
    };

    state = {
        data: null,
        title: '',
        classes: ''
    };

    componentDidUpdate(prevProps) {
        if (!prevProps.isVisible && this.props.isVisible) {
            this.setState({
                data: this.props.data,
                title: this.props.title,
                classes: this.props.classes
            });

            this.props.onOpen();
        }
    }

    handleCloseModal = () => {
        this.props.dispatch(closeModal());
    };

    exitedFinished = () => {
        this.setState({
            data: null,
            title: '',
            classes: ''
        });
    };

    render() {
        return (
            <div>
                <Modal
                    toggle={this.handleCloseModal}
                    id={'default-dialog-container'}
                    className={this.state.classes}
                    onClosed={this.exitedFinished}
                    isOpen={this.props.isVisible}>
                    <ModalBody>
                        <div className={'modal-close'} onClick={this.handleCloseModal}>
                            <span/>
                            <span/>
                        </div>
                        {this.state.data}
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps({ modal }) {
    return {
        dialogStyle: modal.activeModal.dialogStyle,
        data: modal.activeModal.data,
        onOpen: modal.activeModal.onOpen,
        onClose: modal.activeModal.onClose,
        title: modal.activeModal.title,
        classes: modal.activeModal.classes,
        hideTitle: modal.activeModal.hideTitle,
        isVisible: modal.isModalVisible
    };
}

export default connect(
    mapStateToProps
)(ModalContainer);
