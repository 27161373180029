import { ELOQUENT } from 'config/constants';

/**
 * Register user
 * ERRORS:
 * * 422 - email already exists
 * @param {Object} data
 * @returns {Promise}
 */
export function register(data) {
    return ELOQUENT.Auth.register(data);
}

/**
 * Login user with email and password
 * ERRORS:
 * * 401 - wrong email or password
 * * 422 - email not verified
 * @param {String} email
 * @param {String} password
 * @param {Number} type
 * @returns {Promise}
 */
export function login(email, password, type) {
    return ELOQUENT.Auth.login(email, password, type);
}

/**
 * Logout user
 * @returns {Promise}
 */
export function logout() {
    return ELOQUENT.Auth.logout();
}

/**
 * check user
 * @param {String} email
 * @param {Number} type
 * @returns {Promise}
 */
export function check(email, type) {
    return ELOQUENT.Auth.check(email, type);
}

/**
 * request a password reset code by the given email
 * ERRORS:
 * * 404 - email not found
 * @param {String} email
 * @returns {Promise}
 */
export function requestPasswordReset(email) {
    return ELOQUENT.Auth.sendResetPasswordCode(email);
}

/**
 * reset the user's password
 * ERRORS:
 * * 404 - code not found
 * * 422 - code expired
 * @param {String} code
 * @param {String} password
 * @returns {Promise}
 */
export function resetPassword(code, password) {
    return ELOQUENT.Auth.resetPassword(code, password);
}

/**
 * Sets the the authorization token for api requests
 * @param {String} token
 */
export function setApiRequestToken(token) {
    window.localStorage.setItem('appToken', token);
    ELOQUENT.setApiRequestToken(token);
}

/**
 * Unsets the the authorization token for api requests
 */
export function unsetApiRequestToken() {
    window.localStorage.removeItem('appToken');
    ELOQUENT.unsetApiRequestToken();
}

export function getApiRequestToken() {
    return window.localStorage.getItem('appToken');
}

export function passwordChange(oldPassword, newPassword) {
    return ELOQUENT.Auth.passwordChange({
        password_old: oldPassword,
        password_new: newPassword
    });
}
