import PropTypes from 'prop-types';
import React from 'react';
import Spinner from 'components/Spinner/Spinner';
import './processing.scss';

Processing.propTypes = {
    isProcessing: PropTypes.bool,
    text: PropTypes.string
};

export default function Processing(props) {
    if (!props.isProcessing) {
        return null;
    }

    return (
        <div className='processing'>
            <div className='processing__overlay'/>
            <div className='processing__wrapper'>
                <Spinner color='#7f09ad'/>
                {props.text ? <p>{props.text}</p> : null}
            </div>
        </div>
    );
}
