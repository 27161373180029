import PropTypes from 'prop-types';
import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import 'lazysizes';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import 'styles/style.scss';
import AppLoadingContainer from 'containers/AppLoading/AppLoadingContainer';
import PageLoadable from 'components/Loadable/PageLoadable';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import HeaderNav from 'components/HeaderNav/HeaderNav';
import { ToastContainer, toast } from 'react-toastify';
import ModalContainer from '../../components/Modal/Modal';
import { closeModal } from '../../redux/modules/modal';

const AsyncNotFoundPage = PageLoadable({ loader: () => import('containers/NotFoundPage/NotFoundPage') });
const AsyncLanding = PageLoadable({ loader: () => import('containers/Landing/LandingContainer') });
const AsyncSearchResults = PageLoadable({ loader: () => import('containers/SearchResults/SearchResultsContainer') });
const AsyncProduct = PageLoadable({ loader: () => import('containers/Product/ProductContainer') });
const AsyncRegister = PageLoadable({ loader: () => import('containers/Register/RegisterContainer') });
const AsyncLogin = PageLoadable({ loader: () => import('containers/Login/LoginContainer') });
const AsyncCheckout = PageLoadable({ loader: () => import('containers/Checkout/CheckoutContainer') });
const AsyncMyAuctions = PageLoadable({ loader: () => import('containers/MyAuctions/MyAuctionsContainer') });
const AsyncMyBids = PageLoadable({ loader: () => import('containers/MyBids/MyBidsContainer') });
const AsyncMyInventory = PageLoadable({ loader: () => import('containers/MyInventory/MyInventoryContainer') });
const AsyncNotifications = PageLoadable({ loader: () => import('containers/Notifications/NotificationsContainer') });
const AsyncBuyerOrders = PageLoadable({ loader: () => import('containers/MyOrders/Buyer/OrdersContainer') });
const AsyncProfile = PageLoadable({ loader: () => import('containers/MyProfile/MyProfileContainer') });
const AsyncEditProduct = PageLoadable({ loader: () => import('containers/EditProduct/EditProductContainer') });

PrivateRoute.propTypes = {
    component: PropTypes.func.isRequired,
    isAuthed: PropTypes.bool.isRequired
};

function PrivateRoute({ component: Component, isAuthed, ...rest }) {
    return (
        <Route {...rest} render={(routeProps) => (
            isAuthed
                ? <Component {...routeProps} />
                : <Redirect to={{
                    pathname: '/',
                    state: { from: routeProps.location }
                }}/>
        )}/>
    );
}

class AppContainer extends Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        location: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        me: PropTypes.object.isRequired,
        isAuthed: PropTypes.bool.isRequired
    };

    state = {
        isScrolled: false
    };

    componentDidMount() {
        AsyncNotFoundPage.preload();
        document.addEventListener('scroll', this.onScrollChange);
    }

    componentDidUpdate(oldProps) {
        if (oldProps.history.location.pathname !== this.props.history.location.pathname) {
            this.props.dispatch(closeModal());
        }
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.onScrollChange);
    }

    onScrollChange = (e) => {
        if (window.scrollY > 50 && !this.state.isScrolled) {
            this.setState({
                isScrolled: true
            });
        } else if (window.scrollY < 50 && this.state.isScrolled) {
            this.setState({
                isScrolled: false
            });
        }
    };

    onSearch = (location) => {
        this.props.history.push(location);
    };

    render() {
        const { location } = this.props;

        return (
            <div id='page-wrap'>
                <HeaderNav
                    onSearch={this.onSearch}
                    location={location}
                    isAuthed={this.props.isAuthed}
                    me={this.props.me}
                    history={this.props.history}
                    isScrolled={this.state.isScrolled}
                    dispatch={this.props.dispatch}/>
                <ErrorBoundary location={location}>
                    <AppLoadingContainer location={location}>
                        <Switch>
                            <Route path='/' exact={true} component={AsyncLanding} />
                            <Route path='/search' exact={true} component={AsyncSearchResults} />
                            <Route path='/my-auctions' exact={true} component={AsyncMyAuctions} />
                            <Route path='/product/:id' exact={true} component={AsyncProduct} />
                            <Route path='/register/:type' exact={true} component={AsyncRegister} />
                            <Route path='/login/:type' exact={true} component={AsyncLogin} />
                            <PrivateRoute
                                isAuthed={this.props.isAuthed}
                                path='/notifications'
                                exact={true}
                                component={AsyncNotifications}/>
                            <PrivateRoute
                                isAuthed={this.props.isAuthed}
                                path='/my-auctions'
                                exact={true}
                                component={AsyncMyAuctions}/>
                            <PrivateRoute
                                isAuthed={this.props.isAuthed}
                                path='/dashboard'
                                exact={true}
                                component={AsyncMyBids}/>
                            <PrivateRoute
                                isAuthed={this.props.isAuthed}
                                path='/checkout/:auction/:bid'
                                exact={true}
                                component={AsyncCheckout}/>
                            <PrivateRoute
                                isAuthed={this.props.isAuthed}
                                path='/my-inventory'
                                exact={true}
                                component={AsyncMyInventory}/>
                            <PrivateRoute
                                isAuthed={this.props.isAuthed}
                                path='/my-orders'
                                exact={true}
                                component={AsyncBuyerOrders}/>
                            <PrivateRoute
                                isAuthed={this.props.isAuthed}
                                path='/account'
                                exact={true}
                                component={AsyncProfile}/>
                            <PrivateRoute
                                isAuthed={this.props.isAuthed}
                                path='/product-form/new'
                                exact={true}
                                component={AsyncEditProduct}/>
                            <PrivateRoute
                                isAuthed={this.props.isAuthed}
                                path='/product-form/edit/:id'
                                exact={true}
                                component={AsyncEditProduct}/>
                            <Route component={AsyncNotFoundPage} />
                        </Switch>
                    </AppLoadingContainer>
                </ErrorBoundary>
                <ModalContainer/>
                <ToastContainer autoClose={5000} position={toast.POSITION.BOTTOM_LEFT} toastClassName='toaster-custom'/>
            </div>
        );
    }
}

function mapStateToProps({ authentication }) {
    return {
        isAuthed: authentication.isAuthed,
        me: authentication.profileData
    };
}

export default withRouter(
    connect(
        mapStateToProps
    )(AppContainer)
);
