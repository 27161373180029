import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import './signup-modal.scss';
import { closeModal } from '../../redux/modules/modal';

export default class SignupModal extends PureComponent {
    static propTypes = {
        history: PropTypes.object,
        type: PropTypes.string.isRequired,
        dispatch: PropTypes.func
    };

    state = {
        option: 1
    };

    selectOption = (option) => {
        this.setState({
            option
        });
    };

    closeModal = () => {
        this.props.dispatch(closeModal());
    };

    render() {
        return (
            <div className={'signup-modal'}>
                <h3>{this.props.type === 'register' ? 'Create an Account' : 'Login'}</h3>
                <p>{this.props.type === 'register' ? 'Start your Tender account by signing up below' : 'Login to your account and join the action!'}</p>

                <div className={'signup-modal__row'}>
                    <div className={`signup-modal__option ${this.state.option === 1 ? 'selected' : ''}`} onClick={() => this.selectOption(1)}>
                        <img src={'/images/buyer.png'} alt={''}/>
                        <span>I'm a</span>
                        <p>Buyer</p>
                    </div>
                    <div className={`signup-modal__option ${this.state.option === 2 ? 'selected' : ''}`} onClick={() => this.selectOption(2)}>
                        <img src={'/images/seller.png'} alt={''}/>
                        <span>I'm a</span>
                        <p>Seller</p>
                    </div>
                </div>

                {this.props.type === 'register' ? <Link to={'/register/' + this.state.option} className={'btn btn--primary'} onClick={this.closeModal}>Sign Up</Link> : null}
                {this.props.type === 'login' ? <Link to={'/login/' + this.state.option} className={'btn btn--primary'} onClick={this.closeModal}>Login</Link> : null}
            </div>
        );
    }
}
