import { ELOQUENT } from 'config/constants';

/**
 * Get logged in user's profile data
 * @returns {Promise}
 */
export function getAuthedProfile() {
    return ELOQUENT.User.me();
}

/**
 * Update a user's profile data
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise}
 */
export function updateUserProfile(id, data) {
    return ELOQUENT.User.updateOne(id, data);
}
