import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import './header-nav.scss';
import SearchInput from '../SearchInput/SearchInput';
import { enqueueModal } from '../../redux/modules/modal';
import SignupModal from '../SignupModal/SignupModal';
import UserButton from './UserButton';
import Notifications from './Notifications';

export default class HeaderNav extends PureComponent {
    static propTypes = {
        children: PropTypes.any,
        location: PropTypes.object,
        me: PropTypes.object,
        history: PropTypes.object,
        isScrolled: PropTypes.bool,
        isAuthed: PropTypes.bool,
        dispatch: PropTypes.func,
        onSearch: PropTypes.func
    };

    state = {
        isMenuOpened: false,
        searchExpanded: false
    };

    componentDidMount() {
    }

    toggleMenu = (e) => {
        e.preventDefault();

        this.setState({
            isMenuOpened: !this.state.isMenuOpened
        });
    };

    expandSearch = (e) => {
        e.preventDefault();

        this.setState({
            searchExpanded: !this.state.searchExpanded
        });
    };

    onCreateAccount = (type) => {
        this.props.dispatch(enqueueModal({
            data: <SignupModal type={type} dispatch={this.props.dispatch}/>,
            hideTitle: false
        }));
    };

    render() {
        if (this.props.location.pathname === '/') {
            return null;
        }

        return (
            <div className={`header ${this.props.isScrolled ? 'is-scrolled' : ''}`}>
                <div className={'container'}>
                    <Link to={'/'}>
                        <img src={'/images/logo-white.png'} alt={''} className={'header__logo'}/>
                    </Link>
                    <div className={`header__search ${this.state.searchExpanded ? 'expanded' : ''}`}>
                        <SearchInput onSearch={this.props.onSearch} location={this.props.location}/>
                    </div>
                    <div className={'header__icons'}>
                        <a href={'#/'} className={'header__icon'} onClick={this.expandSearch}><i className={'fas fa-search'}/></a>
                    </div>
                    {this.props.isAuthed ? (
                        <div className={'header__actions'}>
                            <Notifications history={this.props.history}/>
                            <UserButton
                                me={this.props.me}
                                dispatch={this.props.dispatch}
                                location={this.props.location}
                                history={this.props.history}/>
                        </div>
                    ) : (
                        <div className={'header__actions'}>
                            <button className={'btn btn--outline'} onClick={() => this.onCreateAccount('login')}>Login</button>
                            <button className={'btn btn--outline'} onClick={() => this.onCreateAccount('register')}>Register</button>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
