import { ELOQUENT } from 'config/constants';

/**
 * Get all categories
 * @returns {Promise}
 */
export function getCategories() {
    return ELOQUENT.Category.getAll();
}

/**
 * Get trending products of category
 * @param {Number} categoryId
 * @returns {Promise}
 */
export function getTrending(categoryId) {
    return ELOQUENT.Product.getTrending({
        category_id: categoryId
    });
}

/**
 * Get single product
 * @param {Number} id
 * @returns {Promise}
 */
export function getProduct(id) {
    return ELOQUENT.Product.getProduct(id);
}

/**
 * Get similar products
 * @param {Array} ids
 * @returns {Promise}
 */
export function getSimilar(ids) {
    return ELOQUENT.Product.getSimilar({
        tag_ids: ids
    });
}

/**
 * Search products
 * @param {Object} data
 * @returns {Promise}
 */
export function searchProducts(data) {
    return ELOQUENT.Product.search(data);
}

/**
 * Get brands of products of a category
 * @param {Number} categoryId
 * @returns {Promise}
 */
export function getBrands(categoryId) {
    return ELOQUENT.Product.getBrands({
        category_id: categoryId
    });
}

/**
 * Create a new suggestion
 * @param {String} name
 * @param {String} url
 * @returns {Promise}
 */
export function makeSuggestion(name, url) {
    return ELOQUENT.Suggestion.createOne({
        product: name,
        url
    });
}

/**
 * Search for tags
 * @param {String} search
 * @returns {Promise}
 */
export function searchTags(search) {
    return ELOQUENT.Product.searchTags(search);
}

/**
 * Search for colors
 * @param {String} search
 * @returns {Promise}
 */
export function searchColors(search) {
    return ELOQUENT.Product.searchColors(search);
}

/**
 * Add product
 * @param {Object} data
 * @returns {Promise}
 */
export function editProduct(data) {
    return ELOQUENT.Product.editProduct(data);
}

/**
 * Delete a product
 * @param {String} id
 * @returns {Promise}
 */
export function deleteProduct(id) {
    return ELOQUENT.Product.deleteOne(id);
}
