import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import './header-nav.scss';
import {
    getNotificationCount,
    getShortNotifications,
    readNotifications,
    readOneNotifications
} from '../../api/notifications';
import Processing from '../Spinner/Processing';
import CountDown from '../CountDown/CountDown';
import moment from 'moment-timezone';

export default class Notifications extends PureComponent {
    static propTypes = {
        history: PropTypes.object.isRequired
    };

    state = {
        isMenuOpened: false,
        isProcessing: false,
        notifications: [],
        count: 0
    };

    componentDidMount() {
        this.loadNotificationsCount();

        document.addEventListener('click', this.documentClicked, true);
        this.polling = window.setInterval(this.loadNotificationsCount, 10000);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.documentClicked);
        window.clearInterval(this.polling);
    }

    loadNotificationsCount = async () => {
        try {
            const resp = await getNotificationCount();

            this.setState({
                count: resp.data.notifications
            });
        } catch (err) {
            console.error(err);
        }
    }

    renderNotification = (not) => {
        if (not.type === 1) {
            return this.renderNotification1(not);
        } else if (not.type === 2) {
            return this.renderNotification2(not);
        }

        return null;
    }

    visitProduct = async (not) => {
        this.toggleMenu();
        this.props.history.push(`/product/${not.data.product_id}`);

        if (!not.read) {
            await readOneNotifications(not.id);
            this.setState({
                count: this.state.count - 1
            });
        }
    }

    renderNotification1 = (not) => {
        return (
            <div key={not.data.id} className={`header-not__item ${not.read ? '' : 'unread'}`} onClick={() => this.visitProduct(not)}>
                <img src={'/images/not-icon-1.png'} alt={''}/>
                <div className={'header-not__info'}>
                    <h2>New Auction!</h2>
                    <p>for product <strong>{not.data.product_name}</strong> has started.</p>
                </div>
                <div className={'header-not__time'}>
                    <CountDown isTiny={true} isPast={true} dateToCount={moment.tz(not.created_at, 'UTC').local().toDate()}/>
                </div>
            </div>
        );
    };

    renderNotification2 = (not) => {
        return (
            <div key={not.data.id} className={`header-not__item ${not.read ? '' : 'unread'}`} onClick={() => this.visitProduct(not)}>
                <img src={'/images/not-icon-1.png'} alt={''}/>
                <div className={'header-not__info'}>
                    <h2>New bidder!</h2>
                    <p>for <strong>{not.data.product_name}</strong> from <strong>{not.data.seller_name} - <span>{not.data.bid_price}₱</span></strong></p>
                </div>
                <div className={'header-not__time'}>
                    <CountDown isTiny={true} isPast={true} dateToCount={moment.tz(not.created_at, 'UTC').local().toDate()}/>
                </div>
            </div>
        );
    };

    loadNotifications = async () => {
        this.setState({
            isProcessing: true
        });

        try {
            const notifications = await getShortNotifications();

            this.setState({
                notifications: notifications.data.map((not) => {
                    not.data = JSON.parse(not.data || '{}');

                    return not;
                })
            });
        } catch (e) {
            this.setState({
                notifications: []
            });
        } finally {
            this.setState({
                isProcessing: false
            });
        }
    }

    toggleMenu = (e) => {
        e && e.preventDefault();

        this.setState({
            isMenuOpened: !this.state.isMenuOpened
        }, () => {
            if (this.state.isMenuOpened) {
                this.loadNotifications();
            }
        });
    };

    documentClicked = (e) => {
        if (this.state.isMenuOpened) {
            const notificationComp = e.target.closest('.header-not');

            if (!notificationComp) {
                this.toggleMenu();
            }
        }
    }

    markAllAsRead = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            isProcessing: true
        });

        try {
            await readNotifications();
            await this.loadNotifications();

            this.setState({
                count: 0
            });
        } finally {
            this.setState({
                isProcessing: false
            });
        }
    }

    visitNotifications = () => {
        this.toggleMenu();
        this.props.history.push('/notifications');
    }

    render() {
        const count = this.state.count;

        return (
            <div className={'header-not'}>
                <div className={'header-not__avatar'} onClick={this.toggleMenu}>
                    <img src={'/images/bell.png'} alt={''}/>
                </div>
                <div className={`header-not__box ${this.state.isMenuOpened ? 'opened' : ''}`}>
                    <div className={'header-not__box-top'}>
                        <h3>Notifications</h3>
                        <div className={'header-not__box-link'} onClick={this.markAllAsRead}>Mark all as read</div>
                    </div>
                    <div className={'header-not__list'}>
                        {this.state.notifications.length ? this.state.notifications.map((not) => {
                            return this.renderNotification(not);
                        }) : <p className={'header-not__error'}>You have no notifications!</p>}
                    </div>
                    <div className={'header-not__footer'} onClick={this.visitNotifications}>
                        View All Notifications
                    </div>
                    <Processing isProcessing={this.state.isProcessing}/>
                </div>
                {count ? <div className={'header-not__count'}>
                    {count}
                </div> : null}
            </div>
        );
    }
}
