import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import './modals.scss';
import { closeModal } from '../../redux/modules/modal';
import Processing from '../Spinner/Processing';
import { toast } from 'react-toastify';
import { makeSuggestion } from '../../api/products';

export default class Suggestion extends PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired
    };

    state = {
        isProcessing: false,
        product: {
            name: '',
            link: ''
        }
    };

    updateField = (field, value) => {
        this.setState({
            product: {
                ...this.state.product,
                [field]: value
            }
        });
    }

    suggestProduct = async (e) => {
        e.preventDefault();

        this.setState({
            isProcessing: true
        });

        try {
            await makeSuggestion(this.state.product.name, this.state.product.link);
            toast.success('Your suggestion was submitted');
        } catch (err) {
            toast.error('Error! Failed to submit your suggestion.');
        } finally {
            this.setState({
                isProcessing: false
            });

            this.props.onClose();
        }
    };

    render() {
        return (
            <div className={'cmodal'}>
                <img src={'/images/suggest.png'} alt={''} className={'cmodal__top-img'}/>
                <h3>Grow with us!</h3>
                <p>Want to sell a product that you don’t find in our database? We’ll get it for you.</p>
                <form onSubmit={this.suggestProduct}>
                    <div className={'form-group-c'}>
                        <label htmlFor='name'>PRODUCT NAME</label>
                        <input
                            type={'text'}
                            name={'name'}
                            id={'name'}
                            onInput={(e) => this.updateField('name', e.target.value)}
                            className={'form-control-c'}
                            placeholder={'Product Name'}
                            required={true}/>
                    </div>
                    <div className={'form-group-c'}>
                        <label htmlFor='link'>PRODUCT WEB LINK</label>
                        <input
                            type={'text'}
                            name={'link'}
                            id={'link'}
                            onInput={(e) => this.updateField('link', e.target.value)}
                            className={'form-control-c'}
                            placeholder={'Product Web Link'}
                            required={true}/>
                    </div>
                    <button className={'btn btn--primary'}>Suggest Product</button>
                </form>
                <Processing isProcessing={this.state.isProcessing}/>
            </div>
        );
    }
}
