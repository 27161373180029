import PropTypes from 'prop-types';
import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import Spinner from 'components/Spinner/Spinner';
import { getAuthedProfile } from '../../api/user';
import { getApiRequestToken, setApiRequestToken, unsetApiRequestToken } from '../../api/auth';
import { isAuthed } from '../../redux/modules/authentication';

class AppLoadingContainer extends Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        children: PropTypes.element.isRequired,
        location: PropTypes.object.isRequired
    }

    state = {
        appIsReady: false
    }

    async componentDidMount() {
        this.validatedAuth();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.location !== nextProps.location) {
            return true;
        }

        if (this.state.appIsReady !== nextState.appIsReady) {
            return true;
        }

        return false;
    }

    validatedAuth = async () => {
        const appToken = getApiRequestToken();

        if (!appToken) {
            this.setState({
                appIsReady: true
            });
        }

        try {
            setApiRequestToken(appToken);
            const resp = await getAuthedProfile();

            this.props.dispatch(isAuthed(resp.data));

            this.setState({
                appIsReady: true
            });
        } catch (err) {
            unsetApiRequestToken();
            this.setState({
                appIsReady: true
            });
        }
    }

    render() {
        if (this.state.appIsReady) {
            return this.props.children;
        }

        return (
            <main>
                <div className='loading-container text-center'>
                    <Spinner useDefaultColor={true} />
                </div>
            </main>
        );
    }
}

export default connect()(AppLoadingContainer);
