const CLOSE_MODAL = 'CLOSE_MODAL';
const SET_ACTIVE_MODAL = 'SET_ACTIVE_MODAL';
const QUEUE_MODAL_DATA = 'QUEUE_MODAL_DATA';

export function enqueueModal(options) {
    return async function(dispatch, getState) {
        const modalState = getState().modal;
        const onQueue = modalState.onQueue;

        onQueue.push({
            data: options.data,
            title: options.title,
            hideTitle: options.hideTitle,
            classes: options.classes || '',
            onOpen: options.onOpen || (() => {}),
            onClose: options.onClose || (() => {}),
            dialogStyle: options.dialogStyle || {}
        });

        if (onQueue.length === 1) {
            await dispatch(setActiveModal(onQueue));
        } else {
            await dispatch(queueData(onQueue));
        }
    };
}

export function closeModal() {
    return async function(dispatch, getState) {
        const onQueue = getState().modal.onQueue;

        onQueue.shift();

        await dispatch(setCloseModal(onQueue));

        window.setTimeout(() => {
            dispatch(setActiveModal(onQueue));
        }, 1000);
    };
}

function queueData(onQueue) {
    return {
        type: QUEUE_MODAL_DATA,
        onQueue
    };
}

function setActiveModal(onQueue) {
    return {
        type: SET_ACTIVE_MODAL,
        activeModal: (onQueue.length > 0) ? onQueue[0] : {},
        isVisible: !!onQueue.length,
        onQueue
    };
}

function setCloseModal(onQueue) {
    return {
        type: CLOSE_MODAL,
        onQueue
    };
}

const initialState = {
    activeModal: {},
    onQueue: [],
    isModalVisible: false
};

export default function modal(state = initialState, action) {
    switch (action.type) {
        case QUEUE_MODAL_DATA:
            return {
                ...state,
                onQueue: action.onQueue
            };
        case SET_ACTIVE_MODAL:
            return {
                ...state,
                activeModal: action.activeModal,
                isModalVisible: action.isVisible,
                onQueue: action.onQueue
            };
        case CLOSE_MODAL:
            return {
                onQueue: action.onQueue,
                activeModal: {},
                isModalVisible: false
            };
        default:
            return state;
    }
}
